<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="head">
          <button
            type="button"
            class="btn btn-danger"
            @click="close"
            aria-label="Close modal"
            style="color: white"
          >
            Закрыть
          </button>
        </header>

        <div class="modal-body" id="modalDescription">
          <button
            type="button"
            class="btn btn-primary"
            @click="showStudentChart"
            aria-label="Close modal"
            style="color: white"
          >
            Отобразить визуализацию по ученикам
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="showClassVisual()"
            aria-label="Close modal"
            style="color: white; margin-left: 20px"
          >
            Отобразить визуализацию по классам
          </button>
          <!-- <button
            type="button"
            class="btn btn-primary-outline"
            @click="sort()"
            aria-label="Close modal"
            style="color: black; margin-left: 20px"
          >
            Сортировка
          </button> -->
          <br />
          <div
            v-if="
              false &&
              FirstOpenPage &&
              showPreloaderLastCheckBool.check == false
            "
          >
            <Preloader />
          </div>
          <apexchart
            ref="chartStudents"
            type="bar"
            v-show="!loadingStudOrClass"
            :height="heightChartStudent"
            :options="chartOptionsStudents"
            :checkShowPreloaderOnAppexChartBool="showPreloaderLastCheckBool"
            @checkShowPreloaderOnAppexChart="checkShowPreloaderOnAppexChart"
            :series="seriesStudents"
          ></apexchart>

          <br />
          <button
            type="button"
            class="btn btn-primary"
            @click="visualData()"
            aria-label="Close modal"
            style="color: white; margin-left: 20px"
          >
            Отобразить визуализацию по питанию
          </button>
          <apexchart
            v-if="showEatingChart"
            width="95%"
            height="500px"
            type="bar"
            ref="chart"
            :options="chartOptions"
            :series="series"
            @click="clickOnColumn"
          ></apexchart>

          <div v-show="showEatingChart" style="display: flex; flex-wrap: wrap">
            <apexchart
              type="bar"
              ref="chartCatAllDay"
              height="380"
              width="400px"
              :options="chartOptionsCatAllDay"
              :series="seriesCatAllDay"
            ></apexchart>
            <apexchart
              type="pie"
              width="380"
              ref="pieOneDay"
              :options="pieCatOneDayOpt"
              :series="pieCatOneDaySeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<!-- 
<script src="https://cdn.jsdelivr.net/npm/apexcharts"></script> -->
<script>
// import TutorialDataService from "../services/TutorialDataService";
const VueApexCharts = () => import("vue-apexcharts");
const Preloader = () => import("./Preloader.vue");
//import VueApexCharts from "vue-apexcharts";

export default {
  name: "modal",
  components: { apexchart: VueApexCharts, Preloader: Preloader },
  props: [
    "marks",
    "selectedClass",
    "classList",
    "studentsList",
    "dateBefore",
    "dateFrom",
  ],
  data() {
    return {
      FirstOpenPage: false,
      loadingStudOrClass: true,
      chartLoad: true,
      heightChartStudent: "auto",
      showEatingChart: false,

      showPreloaderLastCheckBool: { check: false },

      series: [
        {
          name: "Количество питающихся",
          data: [21, 22, 10, 28, 16, 21, 13, 30],
        },
      ],
      chartOptions: {
        title: {
          text: "Питание по дням:",
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            color: "#444",
          },
        },
        chart: {
          height: 350,
          type: "bar",
          // events: {
          //   click(event, chartContext, config) {
          //     // this.clickOnColumn(config.seriesIndex);
          //     console.log(config.seriesIndex);
          //   },
          // },
        },
        plotOptions: {
          bar: {
            columnWidth: "85%",
            distributed: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            ["John", "Doe"],
            ["Joe", "Smith"],
          ],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      pieCatOneDaySeries: [44],
      pieCatOneDayOpt: {
        title: {
          text: "Категории (выбранный день)",
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            color: "#444",
          },
        },
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Team A"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      seriesCatAllDay: [
        {
          data: [400],
        },
      ],
      chartOptionsCatAllDay: {
        chart: {
          type: "bar",
          height: 380,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",

          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          width: 1,
        },
        xaxis: {
          categories: ["test"],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: "Категории (диапазон)",
          align: "center",
          floating: true,
        },
      },

      seriesStudents: [
        {
          name: "Присутствовал",
          data: [44, 55],
        },
        {
          name: "Другое",
          data: [53, 32],
        },
      ],
      chartOptionsStudents: {
        chart: {
          type: "bar",
          stacked: true,
        },
        colors: [
          "#32CD32",
          "#DC143C",
          "#FF1493",
          "#20B2AA",
          "#556B2F",
          "#00BFFF",
          "#8B008B",
          "#708090",
          "black",
        ],
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Детализация по ученикам",
          align: "center",
        },
        xaxis: {
          categories: ["Ученик А", "Ученик Б"],
          // labels: {
          //   formatter: function (val) {
          //     return val + "K";
          //   },
          // },
        },
        yaxis: {
          title: {
            text: undefined,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return val + "K";
        //     },
        //   },
        // },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          fontSize: "14px",
        },
      },
    };
  },
  methods: {
    checkShowPreloaderOnAppexChart(data) {
      console.log("DATA");
      console.log(data);
    },
    close() {
      this.$emit("close");
    },
    showPreloader() {
      this.FirstOpenPage = true;
      this.loadingStudOrClass = true;
    },
    disablePreloader() {
      console.log("123");
      // setTimeout((this.showPreloaderLastCheck = false), 10000);
    },
    async showClassVisual() {
      await this.showClassChart();
      setTimeout(this.disablePreloader(), 2000);
    },
    clickOnColumn(event, chartContext, index) {
      console.log(index.seriesIndex);
      console.log(this.chartOptions.xaxis.categories[index.dataPointIndex]);

      let day = this.chartOptions.xaxis.categories[index.dataPointIndex];
      let catList = new Set();
      let dataToChart = [];

      for (let i = 0; i < this.marks.length; i++) {
        if (this.marks[i].causesID === "Питался")
          catList.add(this.marks[i].cat);
      }
      catList = Array.from(catList);
      for (let i = 0; i < catList.length; i++) {
        dataToChart.push({ cat: catList[i], count: 0 });
      }
      for (let i = 0; i < this.marks.length; i++) {
        if (
          this.marks[i].causesID === "Питался" &&
          this.marks[i].date === day
        ) {
          for (let j = 0; j < catList.length; j++) {
            if (this.marks[i].cat === catList[j]) {
              dataToChart[j].count += 1;
            }
          }
        }
      }

      this.pieCatOneDaySeries = [];
      this.pieCatOneDayOpt.labels = [];
      for (let i = 0; i < dataToChart.length; i++) {
        this.$set(this.pieCatOneDaySeries, i, dataToChart[i].count);
        //this.series[0].data.push(dataObj[i].count);
        // this.chartOptions.xaxis.categories.push([dataObj[i].date]);
        this.$set(this.pieCatOneDayOpt.labels, i, dataToChart[i].cat);
      }

      this.$refs.pieOneDay.updateSeries(this.pieCatOneDaySeries);
      this.$refs.pieOneDay.updateOptions(this.pieCatOneDayOpt, false, true);
    },

    // sort() {
    //   console.log(this.$refs.chartStudents.series);
    // },
    visualData() {
      this.showEatingChart = !this.showEatingChart;

      let setDate = new Set();
      let dataObj = [];
      let catList = new Set();
      let dataToChart = [];

      for (let i = 0; i < this.marks.length; i++) {
        setDate.add(this.marks[i].date);
        catList.add(this.marks[i].cat);
      }

      setDate = Array.from(setDate);
      catList = Array.from(catList);

      for (let i = 0; i < setDate.length; i++) {
        dataObj.push({ date: setDate[i], count: 0 });
      }
      for (let i = 0; i < catList.length; i++) {
        dataToChart.push({ cat: catList[i], count: 0 });
      }
      for (let i = 0; i < this.marks.length; i++) {
        if (this.marks[i].causesID === "Питался") {
          for (let y = 0; y < setDate.length; y++) {
            if (setDate[y] === this.marks[i].date) {
              dataObj[y].count += 1;
            }
          }
          for (let j = 0; j < catList.length; j++) {
            if (catList[j] === this.marks[i].cat) {
              dataToChart[j].count += 1;
            }
          }
        }
      }

      this.series[0].data = [];
      this.chartOptions.xaxis.categories = [];
      this.seriesCatAllDay[0].data = [];
      this.chartOptionsCatAllDay.xaxis.categories = [];
      for (let i = 0; i < dataObj.length; i++) {
        this.$set(this.series[0].data, i, dataObj[i].count);
        this.$set(this.chartOptions.xaxis.categories, i, dataObj[i].date);
      }
      for (let i = 0; i < dataToChart.length; i++) {
        this.$set(this.seriesCatAllDay[0].data, i, dataToChart[i].count);
        this.$set(
          this.chartOptionsCatAllDay.xaxis.categories,
          i,
          dataToChart[i].cat
        );
      }
      this.$refs.chartCatAllDay.updateSeries([
        {
          data: this.seriesCatAllDay[0].data,
        },
      ]);
      this.$refs.chartCatAllDay.updateOptions(
        this.chartOptionsCatAllDay,
        false,
        true
      );
      this.$refs.chart.updateSeries([
        {
          data: this.series[0].data,
        },
      ]);
      this.$refs.chart.updateOptions(this.chartOptions, false, true);
      console.log(this.chartOptions, this.series[0].data, this.$refs.chart);

      this.chartLoad = true;
    },
    showStudentChart() {
      if (this.marks.length > 5000) {
        alert(
          "Простите, но слишком много данных. Возможно, Вы не выбрали класс."
        );
      } else {
        this.showPreloader();
        this.loadingStudOrClass = true;
        this.showPreloaderLastCheckBool.check = false;
        this.chartOptionsStudents.title.text =
          "Детализация по ученикам " + this.dateFrom + " - " + this.dateBefore;
        let setCauses = new Set();
        let setStudents = new Set();

        for (let i = 0; i < this.marks.length; i++) {
          setCauses.add(this.marks[i].causesID);
          for (let k = 0; k < this.classList.length; k++) {
            if (this.classList[k].classID === this.marks[i].classID) {
              let checkAddStudent = false;
              for (let j = 0; j < this.studentsList.length; j++) {
                if (this.studentsList[j]._id === this.marks[i].studentID) {
                  this.$set(
                    this.marks[i],
                    "student",
                    this.studentsList[j].FirstName +
                      " " +
                      this.studentsList[j].LastName
                  );
                  checkAddStudent = true;
                  setStudents.add(
                    this.studentsList[j].FirstName +
                      " " +
                      this.studentsList[j].LastName
                  );
                }
              }
              if (checkAddStudent === false) {
                this.$set(
                  this.marks[i],
                  "student",
                  "ОТЧИСЛЕН" + " " + this.marks[i].studentID
                );

                setStudents.add("ОТЧИСЛЕН" + " " + this.marks[i].studentID);
              }
            }
          }
        }
        setStudents = Array.from(setStudents);
        setCauses = Array.from(setCauses);

        setStudents.sort((a, b) => (a > b ? 1 : -1));
        console.log("@@@@@@@@@@@@@@", setCauses, setStudents, chartDataSeries);

        let chartDataSeries = [];

        for (let i = 0; i < setCauses.length; i++) {
          if (setCauses[i] === "") {
            chartDataSeries.push({ name: "Присутствовал", data: [] });
          } else {
            chartDataSeries.push({ name: setCauses[i], data: [] });
          }
        }

        for (let j = 0; j < setStudents.length; j++) {
          for (let k = 0; k < chartDataSeries.length; k++) {
            chartDataSeries[k].data[j] = 0;
          }
        }
        for (let i = 0; i < this.marks.length; i++) {
          if (this.marks[i].causesID === "")
            this.marks[i].causesID = "Присутствовал";
          for (let j = 0; j < setStudents.length; j++) {
            if (setStudents[j] === this.marks[i].student) {
              for (let k = 0; k < chartDataSeries.length; k++) {
                if (chartDataSeries[k].name === this.marks[i].causesID) {
                  chartDataSeries[k].data[j] += 1;
                }
              }
            }
          }
        }

        this.seriesStudents = [];
        this.chartOptionsStudents.xaxis.categories = [];

        for (let i = 0; i < setStudents.length; i++) {
          this.$set(
            this.chartOptionsStudents.xaxis.categories,
            i,
            setStudents[i]
          );
        }
        for (let i = 0; i < chartDataSeries.length; i++) {
          this.$set(this.seriesStudents, i, chartDataSeries[i]);
        }
        this.$refs.chartStudents.updateOptions(
          this.chartOptionsStudents,
          false,
          true
        );
        this.heightChartStudent = 120 + setStudents.length * 25 + "px";
        this.loadingStudOrClass = false;
      }
    },

    async showClassChart() {
      this.showPreloader();
      this.loadingStudOrClass = true;
      this.chartOptionsStudents.title.text =
        "Детализация по классам " + this.dateFrom + " - " + this.dateBefore;
      let setCauses = new Set();
      let chartDataSeries = [];
      //  let objClass = [];
      console.log(this.classList);
      for (let i = 0; i < this.marks.length; i++) {
        setCauses.add(this.marks[i].causesID);
      }
      setCauses = Array.from(setCauses);
      for (let i = 0; i < setCauses.length; i++) {
        if (setCauses[i] === "") {
          chartDataSeries.push({ name: "Присутствовал", data: [] });
        } else {
          chartDataSeries.push({ name: setCauses[i], data: [] });
        }
      }
      for (let j = 0; j < this.classList.length; j++) {
        for (let k = 0; k < chartDataSeries.length; k++) {
          chartDataSeries[k].data[j] = 0;
        }
      }
      for (let i = 0; i < this.marks.length; i++) {
        if (this.marks[i].causesID === "")
          this.marks[i].causesID = "Присутствовал";
        for (let j = 0; j < this.classList.length; j++) {
          if (this.classList[j].classID === this.marks[i].classID) {
            for (let k = 0; k < chartDataSeries.length; k++) {
              if (chartDataSeries[k].name === this.marks[i].causesID) {
                chartDataSeries[k].data[j] += 1;
              }
            }
          }
        }
      }

      this.seriesStudents = [];
      this.chartOptionsStudents.xaxis.categories = [];

      this.heightChartStudent = 120 + this.classList.length * 25 + "px";
      this.heightChartStudent = this.heightChartStudent + "px";
      for (let i = 0; i < this.classList.length; i++) {
        this.$set(
          this.chartOptionsStudents.xaxis.categories,
          i,
          this.classList[i].className
        );
      }
      for (let i = 0; i < chartDataSeries.length; i++) {
        this.$set(this.seriesStudents, i, chartDataSeries[i]);
      }
      this.loadingStudOrClass = false;
    },
  },
  mounted() {
    console.log("StatVisual open");
  },
};
</script>
<style scoped>
#head {
  width: auto;
  max-height: 300px;
  position: fixed;
  background: #fff;
  z-index: 1;
}

#modalDescription {
  margin-top: 70px;
  overflow: auto;
}
.v-row-group__header td {
  background-color: #9370db;
  color: white;
  height: 20px;
}
.v-data-table.row-height-30 td {
  height: 30px !important;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 90%;

  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
}

.modal-header,
.modal-footer {
  padding: 5px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
  padding: 2px;
}

.search-table label {
  font-size: 11px;
}
</style>
